import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'
import { Image } from 'react-bootstrap';

function HeaderComponent() {
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3" sticky="top" >
          <Container fluid>
            <Navbar.Brand href="/" className="d-flex align-items-center">
              <Image src="n22.png" alt="logo" className="d-inline-block align-top" width={'60%'} height={'60%'} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Image src="n2.png" alt="logo" className="d-inline-block align-top" width={'20%'} height={'20%'} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/"><h5>Home</h5></Nav.Link>
                  <Nav.Link href="/about"><h5>What We Do</h5></Nav.Link>
                  <Nav.Link href="/careers"><h5>Careers</h5></Nav.Link>
                  <Nav.Link href="/solutions"><h5>Solutions</h5></Nav.Link>
                  <Nav.Link href="/technology"><h5>Technology</h5></Nav.Link>
                  <Nav.Link href="/blog"><h5>Blog</h5></Nav.Link>
                  <Nav.Link href="/contact-us"><h5>Contact Us</h5></Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        // <Navbar bg="light" variant="light">
        //   <Container>
        //     <Navbar.Brand href="#home"><Image src="n22.png" alt="logo" className="d-inline-block align-top" width={'60%'}  height={'60%'}/></Navbar.Brand>
        //     <Nav className="me-auto">
        //       <Nav.Link href="/"><h5><span style={{color: 'black'}}>Home</span></h5></Nav.Link>
        //       <Nav.Link href="/about"><h5><span style={{color: 'black'}}>What We Do</span></h5></Nav.Link>
        //       <Nav.Link href="/careers"><h5><span style={{color: 'black'}}>Careers</span></h5></Nav.Link>
        //       <Nav.Link href="/solutions"><h5><span style={{color: 'black'}}>Solutions</span></h5></Nav.Link>
        //       <Nav.Link href="/technology"><h5><span style={{color: 'black'}}>Technology</span></h5></Nav.Link>
        //       <Nav.Link href="/blog"><h5><span style={{color: 'black'}}>Blog</span></h5></Nav.Link>
        //       <Nav.Link href="/contact-us"><h5><span style={{color: 'black'}}>Contact Us</span></h5></Nav.Link>
        //     </Nav>
        //   </Container>
        // </Navbar>
      ))}
    </>
  );
}

export default HeaderComponent;