import { useState, useEffect } from 'react';
import { Carousel, Button, Modal } from 'react-bootstrap';
import './Carousel.css';
import ContactForm from './ContactUs';
import ReplaceTyping from './ReplaceTyping';

const slides = [
    {
        id: 1,
        imgUrl: 'https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1484&q=80',
        title: 'At ABHI IT SOLUTIONS, we believe in the power of teamwork. Let us help you build your dream team.',
        subtitle: 'Subtitle for slide 3',
        buttonLabel: 'Learn More',
        buttonLink: '#section1',
    },
    {
        id: 2,
        imgUrl: 'https://images.unsplash.com/photo-1538688423619-a81d3f23454b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Building a winning team is more than just filling seats - let us help you find the right people',
        subtitle: 'Subtitle for slide 1',
        buttonLabel: 'Learn More',
        buttonLink: '#section1',
    },
    {
        id: 3,
        imgUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Great things are never accomplished alone. Let us help you build a team that can achieve anything',
        subtitle: 'Subtitle for slide 2',
        buttonLabel: 'Learn More',
        buttonLink: '#section2',
    },
    {
        id: 4,
        imgUrl: 'https://img.freepik.com/free-photo/guy-shows-document-girl-group-young-freelancers-office-have-conversation-working_146671-13569.jpg?w=900&t=st=1679061018~exp=1679061618~hmac=4148a441c35a70bbb3701d420df68cbbd6e870f31644c48ae27bad51c0ee70d3',
        title: 'Your success is our success - let us help you assemble a team that can take on any challenge',
        subtitle: 'Subtitle for slide 4',
        buttonLabel: 'Learn More',
        buttonLink: '#section2',
    },
    // add more slides as needed
];

function CarouselComponent() {

    const [index, setIndex] = useState(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);


    return (
        <Carousel activeIndex={index} onSelect={handleSelect} controls={false}  >
            <Carousel.Item key={0} >
                <div
                    className="bg-image"
                    style={{
                        backgroundImage: `url(https://img.freepik.com/free-photo/group-people-working-out-business-plan-office_1303-15861.jpg?w=740&t=st=1679062250~exp=1679062850~hmac=05415fb7ef05a41c2f3b955ebd3ee095140d3e19f7e5dec382623fdf517e07ee)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >

                    <div className="carousel-caption text-center">
                        <h3>Your Search Ends Here</h3>
                        <Button onClick={handleShow} className="read-more" variant="primary" style={{ padding: "14px 14px 24px 24px", fontSize: "20px" }} size="lg">
                            Join Us
                            <div className="curtain">
                                <div className="curtain-top"></div>
                                <div className="curtain-bottom"></div>
                                <div className="text">Welcome</div>
                            </div>
                        </Button>
                        <Modal size="lg" show={show} onHide={handleClose}>
                            <Modal.Body>
                                <ContactForm />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                {/* {loading ? (
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            fontFamily: "cambria, george sans",
                        }}
                    >
                        <ReplaceTyping />
                    </div>
                ) : (

                    <div
                        className="bg-image"
                        style={{
                            backgroundImage: `url(group-people-working-out-business-plan-office.jpg)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <div className="overlay-black"></div>
                        

                        <div className="carousel-caption text-center">
                            <h3>Your Search Ends Here</h3>
                            <Button onClick={handleShow} className="read-more" variant="primary" style={{ padding: "14px 14px 24px 24px", fontSize: "20px" }} size="lg">
                                Join Us
                                <div className="curtain">
                                    <div className="curtain-top"></div>
                                    <div className="curtain-bottom"></div>
                                    <div className="text">Welcome</div>
                                </div>
                            </Button>
                            <Modal size="lg" show={show} onHide={handleClose}>
                                <Modal.Body>
                                    <ContactForm />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                )} */}
            </Carousel.Item>

            {slides.map(slide => (
                <Carousel.Item key={slide.id}>
                    <div
                        className="bg-image"
                        style={{
                            backgroundImage: `url(${slide.imgUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        {/* <div className="overlay-black"></div> */}
                        <div className="carousel-caption ">
                            <h3>{slide.title}</h3>
                            {/* <p>{slide.subtitle}</p> */}
                            {/* <Button href={slide.buttonLink} className="mt-4">{slide.buttonLabel}</Button> */}
                            <Button onClick={handleShow} className="read-more" variant="primary" style={{ padding: "14px 14px 24px 24px", fontSize: "20px" }} size="lg">
                                Join Us
                                <div className="curtain">
                                    <div className="curtain-top"></div>
                                    <div className="curtain-bottom"></div>
                                    <div className="text">Welcome</div>
                                </div>
                            </Button>
                            <Modal size="lg" show={show} onHide={handleClose}>
                                <Modal.Body>
                                    <ContactForm />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>

    )

}

export default CarouselComponent;