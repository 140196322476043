import { useState, useEffect } from 'react';
import { Carousel, Spinner, Button } from 'react-bootstrap';

const items = [
    {
        id: 1,
        imgUrl: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Slide 1',
        subtitle: 'Subtitle for slide 1',
        buttonLabel: 'Learn More',
        buttonLink: '#section1',
    },
    {
        id: 2,
        imgUrl: 'https://images.unsplash.com/photo-1538688423619-a81d3f23454b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Slide 2',
        subtitle: 'Subtitle for slide 2',
        buttonLabel: 'Learn More',
        buttonLink: '#section2',
    },
    {
        id: 3,
        imgUrl: 'https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1484&q=80',
        title: 'Slide 3',
        subtitle: 'Subtitle for slide 3',
        buttonLabel: 'Learn More',
        buttonLink: '#section1',
    },
    {
        id: 4,
        imgUrl: 'http://www.shutterstock.com/blog/wp-content/uploads/sites/5/2016/03/fall-trees-road-1.jpg',
        title: 'Slide 4',
        subtitle: 'Subtitle for slide 4',
        buttonLabel: 'Learn More',
        buttonLink: '#section2',
    },
    // add more slides as needed
];

function LoaderCarousel() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulate loading for 2 seconds
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, []);
  
    return (
      <Carousel>
        {items.map((item) => (
          <Carousel.Item key={item.id}>
            {item.id === 1 && isLoading ? (
              // Show loader on first item
              <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="light" />
              </div>
            ) : (
              <>
                <img
                  className="d-block w-100"
                  src={`https://picsum.photos/id/${item.id}/800/400`}
                  alt={item.title}
                />
                <Carousel.Caption>
                  <h3>{item.title}</h3>
                </Carousel.Caption>
              </>
            )}
          </Carousel.Item>
        ))}
        {!isLoading && (
          // Show message and button on first item after loading
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`https://picsum.photos/id/${items[0].id}/800/400`}
              alt={items[0].title}
            />
            <Carousel.Caption>
              <h3>{items[0].title}</h3>
              <p>Your search for a great team ends here.</p>
              <Button variant="primary">Learn More</Button>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    );
  }
  
export default LoaderCarousel;
