import { Spinner, Row, Col, Container } from 'react-bootstrap';
import './ReplaceTyping.css';


function ReplaceTyping() {

    return (
        <>
            <div className="wrapper">
                <div className="static-txt">Looking for a</div>
                <ul className="dynamic-txts">
                    <li><span>Developer</span></li>
                    <li><span>Designer</span></li>
                    <li><span>Freelancer</span></li>
                </ul>
            </div>
            <Spinner animation="border" variant="primary" />
        </>
    )
}

export default ReplaceTyping;