import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Blog from './BlogCarousel';
import BlogCarousel from './BlogCarousel';
import Career from './Careers';
import CarouselComponent from './Carousel';
import ContactForm from './ContactUs';
import Footer from './Footer';
import GalleryComponent from './Gallery';
import HeaderComponent from './Header';
import Home from './Home';
import BlogCard from './jsxBlogCard';
import LoaderCarousel from './LoaderCarousel';
import LogoSlider from './LogoSlider';
import NotFound from './NotFound';
import ParticleEffects from './Particles';
import ParticleBackground from './Particles';
import Screen from './Screen';
import Services from './Services';
import ServicesTech from './ServicesTech';
import CyclicCardGroup from './Technologies';
import InfographicLayout from './Technologies';
import Testimonials from './Testimonials';


function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <Router>
        <div>
          <Routes>
            <Route path="/" element={
              <>
                <Screen />
                {/* <ParticleBackground /> */}
                <CarouselComponent />
                <Services />
                <hr className='hr' />
                <div style={{ marginBottom: "100px", marginTop: "50px" }}>
                  <ServicesTech />
                </div>
                <InfographicLayout />
                <Blog />
                <Testimonials />

              </>
            } />
            <Route path="/about" element={<Services />} />
            <Route path="/solutions" element={<InfographicLayout />} />
            <Route path="/technology" element={<ServicesTech />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact-us" element={<ContactForm />} />
            <Route path="/careers" element={<Career />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>

      <Footer />

      {/* <HeaderComponent />
      <Router>
      <div>
        <Routes>
          <Route path='/' element={Home} /> 
          <Route path="/about" element={Home} />
        </Routes>
      </div>
    </Router>
    <Footer /> */}



      {/* <HeaderComponent />

      <CarouselComponent />           
      <Services />
      <hr className='hr'/>
      <InfographicLayout />
      <BlogCarousel />      
      <Testimonials />




      
      <Footer /> */}
    </div>
  );
}

export default App;
