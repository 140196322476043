import { Row, Col, Container, Image } from 'react-bootstrap';
import ServicesTech from './ServicesTech';
import './Technologies.css'

function InfographicLayout() {
    return (
        <>
            <div>
                <Container></Container>
            </div>

            <div className="d-flex offer" style={{ marginBottom: "50px" }}>
                <h1>We Provide</h1>
            </div>

            <div className="d-flex flexbox mt-5">




                <div className="flexcard flexcardGreen" >
                    <div className="flexcardNumber flexcardNumberGreen"> 30-Day Job Guarantee </div>
                    <div className="flex flexcardTitle"><Image src="shield.png" alt="Reusable Elements Logo" width={100} className="mr-3" /></div>
                    <div className="flex flexcardText">Our IT staffing company is proud to offer a 30-Day Job Guarantee program to help mitigate the risk for candidates and provide assurance that they will be able to work in a safe, secure and stable environment. This feature helps to attract top talent and differentiate us from other competitors in the market. We are confident in our ability to place candidates in roles that are a good fit for their skills and experience, and we are committed to working with our clients and candidates to ensure a successful placement. With our 30-Day Job Guarantee program, you can trust us to be your reliable and dependable staffing partner. </div>
                    {/* <div className="flex flexcardImg"><img className="flexcardimgItem"
                        src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png" alt="" /></div> */}
                </div>
                <div className="flexcard flexcardBlue" >
                    <div className="flexcardNumber flexcardNumberBlue"> In-House Training </div>
                    <div className="flex flexcardTitle"><Image src="analysis.png" alt="Reusable Elements Logo" width={100} className="mr-3" /></div>
                    <div className="flex flexcardText"> We understand that technology is constantly evolving, and it is essential to stay up-to-date with the latest trends and best practices to remain competitive. Our customized training programs are designed to meet the unique needs of your organization, and our experienced trainers will work closely with your team to ensure they receive the support and guidance they need to succeed. With our In-House Training, you can invest in the professional development of your employees and build a stronger, more capable team. Contact us today to learn more.</div>
                    {/* <div className="flex flexcardImg">
                        <img className="flexcardimgItem"
                            src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png" alt="" /></div> */}
                </div>
                <div className="flexcard flexcardOrange" >
                    <div className="flexcardNumber flexcardNumberOrange"> Seamless VISA Sponsership </div>
                    <div className="flex flexcardTitle"><Image src="boarding-pass.png" alt="Reusable Elements Logo" width={100} className="mr-3" /></div>
                    <div className="flex flexcardText">We understand that navigating the complex VISA process can be daunting, and our team of experienced professionals is here to guide you every step of the way. Our seamless process ensures that your employees can focus on their work while we handle the paperwork, interviews, and legal requirements. With our VISA sponsorship program, you can attract the best and brightest talent from around the globe and build a more diverse and innovative workforce. Contact us today to learn more about our VISA sponsorship program.</div>
                    {/* <div className="flex flexcardImg"><img className="flexcardimgItem"
                        src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png" alt="" /></div> */}
                </div>                
            </div>                        
        </>
    );
}

export default InfographicLayout;