import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();        

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            
        } else {
            try {
                setSubmitting(true);
                const response = await fetch("https://mailer.abhiitsolutions.com/contact", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    setSuccessMessage("Your message has been sent successfully!");
                    setSubmitting('tt');
                } else {
                    setErrorMessage("Failed to send message. Please try again later.");
                    setSubmitting(false);
                }
            } catch (error) {
                console.log(error);
                setErrorMessage("Failed to send message. Please try again later.");
            } 
        }

        setValidated(true);
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Container className="p-3">
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            <Form.Control.Feedback type="invalid">
                Please enter your name.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
                as="textarea"
                name="message"
                rows={5}
                value={formData.message}
                onChange={handleChange}
                required
            />
            <Form.Control.Feedback type="invalid">
                Please enter your message.
            </Form.Control.Feedback>
        </Form.Group>

        <div className="text-center">
            <button type="submit" className="btn btn-primary" disabled={submitting}>
            {submitting ? "Submitting..." : "Submit"}
            </button>
        </div>

        {successMessage && (
            <p className="text-success mt-3">{successMessage}</p>
        )}

        {errorMessage && (
            <p className="text-danger mt-3">{errorMessage}</p>
        )}
    </Form>
</Container>

    );
};

export default ContactForm;
