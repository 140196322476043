// import React from 'react';
// import { Container, Row, Image, Col } from 'react-bootstrap';
// import './Footer.css';

// const Footer = () => {
//     // const svgPath = "M0,100 C150,200 350,0 500,100 L500,0 L0,0 Z";
//     // const svgPath = "M 0,50 C 20,30 30,70 50,50 C 70,30 80,70 100,50 C 120,30 130,70 150,50 C 170,30 180,70 200,50"
//     // const svgPath = "M cx cy m -rx, 0 a rx,ry 0 1,0 (rx * 2),0 a rx,ry 0 1,0 -(rx * 2),0"
//     // const svgPath = "M 0,50 C 20,30 30,60 50,50 C 70,40 80,70 100,50 C 120,30 130,60 150,50 C 170,40 180,70 200,50 L 200,100 L 0,100 Z M 0,100 L 200,100 C 180,120 170,90 150,100 C 130,110 120,80 100,100 C 80,120 70,90 50,100 C 30,110 20,80 0,100 Z"
//     // const svgStyle = {
//     //     position: "relative",
//     //     background: "#fff",
//     //     backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 100' preserveAspectRatio='none'%3E%3Cpath d='${svgPath}' stroke='none' fill='%233d3d3d'/%3E%3C/svg%3E")`,
//     //     backgroundRepeat: "no-repeat",
//     //     backgroundSize: "cover",
//     //     paddingBottom: "5rem", // add padding as needed
//     // };
//     return (
//         // <footer style={svgStyle}>
//         // <footer>

//         // </footer>
//         <>
//         <Container className="footer">
//                 <Row>
//                     <Col md={3}>
//                         <h5>ABITSOLUTIONS</h5>
//                         <p>mail@abitsolutions.com</p>
//                     </Col>
//                     <Col md={3}>
//                         <h5>Learn</h5>
//                         <ul className="list-unstyled">
//                             <li><a href="#">Lorem Ipsum</a></li>
//                             <li><a href="#">Dolor Sit</a></li>
//                             <li><a href="#">Amet Consectetur</a></li>
//                         </ul>
//                     </Col>
//                     <Col md={3}>
//                         <h5>Resources</h5>
//                         <ul className="list-unstyled">
//                             <li><a href="#">Lorem Ipsum</a></li>
//                             <li><a href="#">Dolor Sit</a></li>
//                             <li><a href="#">Amet Consectetur</a></li>
//                         </ul>
//                     </Col>
//                     <Col md={3}>
//                         <h5>Company</h5>
//                         <ul className="list-unstyled">
//                             <li><a href="#">Lorem Ipsum</a></li>
//                             <li><a href="#">Dolor Sit</a></li>
//                             <li><a href="#">Amet Consectetur</a></li>
//                         </ul>
//                     </Col>
//                 </Row>
//                 <hr />
//                 <Row>
//                     <Col md={6}>
//                         &copy; {new Date().getFullYear()} ABITSOLUTIONS
//                     </Col>
//                     <Col md={6}>
//                         <ul className="list-unstyled d-flex justify-content-end">
//                             <li><a href="#"><Image src="light-bulb.png" alt="fb" /></a></li>
//                             <li><a href="#">insta<i className="fab fa-instagram"></i></a></li>
//                             <li><a href="#">linkedin<i className="fab fa-linkedin"></i></a></li>
//                             <li><a href="#">twitter<i className="fab fa-twitter"></i></a></li>
//                         </ul>
//                     </Col>
//                 </Row>
//             </Container></>
//     );
// };

// export default Footer;

import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
    return (
        <div style={{marginTop: '100px'}}>
        <footer className="mt-5 foot " >
            <Container fluid>
                <Row>
                    <Col md={3} className="d-flex flex-column align-items-center justify-content-center">
                        <Image src={"n11.png"} alt="Logo" width={50} height={50} />
                        <p className="text-center">321 W Stewart St<br />Dayton, Ohio<br />45417</p>
                    </Col>
                    <Col md={6} className="d-flex justify-content-center align-items-center">
                        <ul className="footer-links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/solutions">Solutions</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/contact-us">Contact</a></li>
                        </ul>
                    </Col>
                    <Col md={3} className="d-flex flex-column align-items-center justify-content-center">
                        <ul className="social-media">
                            <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                            <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        </ul>
                        <div className="contact-info">
                            <p><FontAwesomeIcon icon={faEnvelope} /> hr@abhiitsolutions.com</p>
                            {/* <p><FontAwesomeIcon icon={faPhoneAlt} /> +1(937)430-1413</p> */}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='cprights'>
                        &copy; {new Date().getFullYear()} ABHIITSOLUTIONS
                    </Col>
                </Row>
            </Container>
        </footer>
        </div>
    );
}

export default Footer;
