import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Carousel, Image } from 'react-bootstrap';
import './Testimonials.css'

// const Testimonials = () => {
//   const [index, setIndex] = useState(0);

//   const handleSelect = (selectedIndex, e) => {
//     setIndex(selectedIndex);
//   };

//   return (
//     <Container className="my-5">
//       <Row className="align-items-center">
//         <Col xs={6}>
//           <h2 className="text-left">Testimonials</h2>
//         </Col>
//         <Col xs={6}>
//           <div className="d-flex justify-content-end align-items-center">
//             <Button
//               className="mr-3"
//               variant="outline-secondary"
//               onClick={() => setIndex((index - 1 + 3) % 3)}
//             >
//               &#8249;
//             </Button>
//             <Button
//               variant="outline-secondary"
//               onClick={() => setIndex((index + 1) % 3)}
//             >
//               &#8250;
//             </Button>
//           </div>
//         </Col>
//       </Row>
//       <Carousel
//         activeIndex={index}
//         onSelect={handleSelect}
//         interval={null}
//         slide={false}
//         className="my-5 cars"
//         controls={false}
//         slidesToShow={2}
//       >
//         <Carousel.Item className='cars'>
//           <Card className="mx-auto testimonial-card" style={{ width: '18rem' }}>
//             <Card.Body>
//               <Card.Title>John Doe</Card.Title>
//               <Card.Subtitle className="mb-2 text-muted">CEO</Card.Subtitle>
//               <Card.Text>
//                 "ABITSOLUTIONS provided us with excellent service and
//                 delivered our project on time and within budget. Highly
//                 recommended!"
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Carousel.Item>
//         <Carousel.Item>
//           <Card className="mx-auto testimonial-card " style={{ width: '18rem' }}>
//             <Card.Body>
//               <Card.Title>Jane Smith</Card.Title>
//               <Card.Subtitle className="mb-2 text-muted">
//                 CTO
//               </Card.Subtitle>
//               <Card.Text>
//                 "We had a great experience working with ABITSOLUTIONS. Their
//                 team was knowledgeable and professional, and we were very
//                 pleased with the end result."
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Carousel.Item>
//         <Carousel.Item>
//           <Card className="mx-auto testimonial-card" style={{ width: '18rem' }}>
//             <Card.Body>
//               <Card.Title>Bob Johnson</Card.Title>
//               <Card.Subtitle className="mb-2 text-muted">CTO</Card.Subtitle>
//               <Card.Text>
//                 "ABITSOLUTIONS helped us build a custom software solution that
//                 has had a tremendous impact on our business. Highly recommend
//                 their services!"
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Carousel.Item>
//         <Carousel.Item>
//           <Card className="mx-auto testimonial-card" style={{ width: '18rem' }}>
//             <Card.Body>
//               <Card.Title>Bob Johnson</Card.Title>
//               <Card.Subtitle className="mb-2 text-muted">CTO</Card.Subtitle>
//               <Card.Text>
//                 "ABITSOLUTIONS helped us build a custom software solution that
//                 has had a tremendous impact on our business. Highly recommend
//                 their services!"
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Carousel.Item>
//       </Carousel>
//     </Container>
//   );
// };

// export default Testimonials;


const items = [
    {
        title: "http://placehold.it/380?text=1",
        subtitle: "Samantha Patel",
        description: `"The team's commitment to providing the best candidate experience is evident in everything they do. It truly makes a difference."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=2",
        subtitle: "Sarah Johnson",
        description: `"The in-house training I received was invaluable. It not only helped me excel in my new role but also gave me skills that will serve me well throughout my career."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=3",
        subtitle: "David Raju",
        description: `"I was nervous about the VISA sponsorship process, but this team made it so easy. They handled everything, and I felt supported every step of the way."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=4",
        subtitle: "Rachel Gupta",
        description: `"The interview process was well-structured, and the team made sure to get to know me as a person, not just a candidate. It made all the difference."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=5",
        subtitle: "GlobalTechh",
        description: `"The scalability and flexibility of their staffing solutions really stood out to us. We were able to expand our team quickly and efficiently thanks to their services."`,
        imageURL: "user.png",
        alt: '',
    },
    // {
    //     title: "http://placehold.it/380?text=5",
    //     subtitle: "Fifth slide",
    //     description: `"The quick response time and efficient communication made me feel valued as a candidate. It's clear that they truly care about finding the right fit for both the candidate and the company."`,
    //     imageURL: "user.png",
    //     alt: '',
    // },
    {
        title: "http://placehold.it/380?text=6",
        subtitle: "Emily Wong",
        description: `"ABHI, really knows how to provide an exceptional candidate experience. From the first conversation to the job offer, everything was handled with care and professionalism."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=7",
        subtitle: "Michael Singh",
        description: `"Thanks to ABHI IT SOLUTIONS, I found a job that exceeded my expectations. I couldn't be happier with the outcome."`,
        imageURL: "user.png",
        alt: '',
    },
    {
        title: "http://placehold.it/380?text=8",
        subtitle: "Kevin Lee",
        description: `ABHI IT SOLUTIONS provided us with excellent service and
                delivered our project on time and within budget. Highly
                recommended!`,
        imageURL: "user.png",
        alt: '',
    },
];

const Testimonials = () => {
    const [itemsPerSlide, setItemsPerSlide] = useState(3);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                setItemsPerSlide(4);
            } else if (window.innerWidth >= 992) {
                setItemsPerSlide(3);
            } else if (window.innerWidth >= 768) {
                setItemsPerSlide(2);
            } else {
                setItemsPerSlide(1);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
        {/* <br /> */}
        <div className="testimonials alt-testimonials" style={{marginTop: '100px'}}>
  <h1>Testimonials
    <span>Directly from our customers</span>
  </h1>
</div>
<div>
        <Carousel interval={10000} style={{ maxHeight: '320px', margin: ' 0 auto' }}>
            {[...Array(Math.ceil(items.length / itemsPerSlide)).keys()].map((index) => (
                <Carousel.Item key={index} style={{ maxHeight: '450px'}} className="p-3">
                    <div className="card-group d-flex justify-content-center flex-wrap">
                        {items
                            .slice(index * itemsPerSlide, (index + 1) * itemsPerSlide)
                            .map((item, itemIndex) => (
                                <div key={itemIndex} className="col-md-3 mb-4">
                                    <Card className="mx-auto card" >
                                        <Card.Body>
                                            {/* <Card.Title className="card-title-custom">{item.subtitle}</Card.Title> */}
                                            <Card.Text style={{textAlign: 'center', padding:'10px'}}>{item.description}</Card.Text>
                                            <div className="align-items-center" style={{float: 'right'}}>
                                                {/* - <Image
                                                    src={item.imageURL}
                                                    alt={item.title}
                                                    roundedCircle
                                                    width={30}
                                                    height={30}
                                                    className="mr-3"
                                                /> */}
                                                <span className="card-title-custom"><i>- {item.subtitle}</i></span>
                                                <div>
                                                    {/* <Card.Subtitle className="text-muted mb-2 card-title-custom"><i>{item.subtitle}</i></Card.Subtitle> */}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    {/* <Card className="mx-auto card ">
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card> */}
                                </div>
                            ))}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
        </div>
        </>
    );
};

export default Testimonials;
