import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from './Screen.module.css'

const Screen = () => {
    const [showScreen, setShowScreen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowScreen(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <a id="top"></a>
            <div className={`${styles.screen} ${showScreen ? "" : styles["screen--hidden"]}`}>
                <Container>
                    <div className={styles.typewriter}>
                        <h1>Welcome to Abhi IT Solutions</h1>
                    </div>
                </Container>
            </div>

        </>
    );
};

export default Screen;
