import { Container, Row, Col, Button, Image, Modal } from 'react-bootstrap';
import { useState } from 'react';
import styles from './ServicesTech.module.css';

function ServicesTech() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return (
        <>
            <div className="d-flex strip" style={{ marginTop: "100px" }}>
                <Container className="d-flex align-items-center justify-content-center">
                    <Row>
                        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
                            <h3>Why Choose Us?</h3>
                            <Image
                                src="https://img.freepik.com/free-vector/flat-young-businessman-sitting-huge-red-question-mark_126523-2881.jpg?w=1060&t=st=1678026551~exp=1678027151~hmac=8ea2e788aa3fba95a917536a0a9667e3b69d64c732acc74513ecdacbe151df40"
                                alt="question mark"
                                width={300}
                                className="mr-md-3 mb-3 mb-md-0"
                            />

                        </Col>
                        <Col className="d-flex align-items-center justify-content-center">
                            <h3>Reimagine your product with progressive solutions</h3>
                        </Col>
                    </Row>
                </Container>
            </div>



            <div className="my-5">
                <div className="d-flex offer">
                    <h1 className={styles.fadeIn}>Technologies</h1>
                </div>
                <div className="d-flex">
                    <Container className={styles.slideIn}>
                        <Row className="my-5">
                            <Col xs={12} md={4} className="text-center mt-3 mb-3">
                                <Image src="blockchain.png" alt="Blockchain Logo" width={100} className="mr-3" />
                                <p className="my-3">"Blockchain is a decentralized digital ledger that allows multiple parties to verify and record transactions in a secure and transparent manner. It uses advanced cryptographic techniques to secure and validate transactions, ensuring that they cannot be altered or tampered with."</p>
                                <Button variant="primary" className={styles.slideInButton}>Learn More</Button>
                            </Col>

                            <Col xs={12} md={4} className="text-center mt-3 mb-3">
                                <Image src="cloud-service.png" alt="Cloud Computing Logo" width={100} className="mr-3" />
                                <p className="my-3">"Cloud computing is the delivery of on-demand computing resources, such as servers, storage, databases, and software, over the internet, providing a scalable and cost-effective solution for businesses and individuals."</p>
                                <Button variant="primary" className={styles.slideInButton}>Learn More</Button>
                            </Col>

                            <Col xs={12} md={4} className="text-center mt-3 mb-3">
                                <Image src="collaborative.png" alt="CRM Logo" width={100} className="mr-3" />
                                <p className="my-3">"Customer Relationship Management (CRM) is a technology-based strategy that uses data analysis and customer interactions to optimize business relationships and improve customer satisfaction and retention."</p>
                                <Button variant="primary" className={styles.slideInButton}>Learn More</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ServicesTech;