import React, { useState } from 'react';
import { Carousel, Card, Button, Modal, Image } from 'react-bootstrap';
import { Jumbotron } from 'react-bootstrap';
import styles from './BlogCard.module.css'

const blogPosts = [
    {
        title: "The Human Side of Hiring: How Building Personal Relationships Can Lead to Better Hiring Outcomes",
        author: 'Abhilash',
        content: `The hiring process is often seen as a transactional one. Companies post job openings, candidates apply, and the most qualified applicant is offered the job. But in reality, the hiring process is much more complex than that. The best candidates are often those who are not just qualified for the job, but who are also a good fit for the company culture. This is where the human side of hiring comes into play.

        In this blog post, we'll explore the importance of building personal relationships in the hiring process, and how doing so can lead to better hiring outcomes. We'll take a look at the data behind the benefits of personal relationships, and provide some tips for building those relationships in a professional setting.
        
        The Data Behind Personal Relationships in Hiring
        
        The benefits of building personal relationships in the hiring process are backed up by data. According to a survey by LinkedIn, 48% of job seekers said they were more likely to consider a company that they had a personal connection with. This means that companies who take the time to build personal relationships with potential hires are more likely to attract top talent.
        
        Furthermore, a study by the Society for Human Resource Management found that employee referral programs lead to higher quality hires, lower turnover rates, and lower recruiting costs. This is because employees who refer candidates often have a personal relationship with them, and can vouch for their skills and cultural fit.
        
        Tips for Building Personal Relationships in Hiring
        
        So how can companies build personal relationships with potential hires? Here are some tips:
        
        Attend Networking Events: Networking events are a great way to meet potential hires in a casual setting. Attend industry events, job fairs, and other networking events to meet potential candidates and start building relationships.
        
        Engage on Social Media: Social media platforms like LinkedIn and Twitter are great tools for building personal relationships. Follow potential hires on social media and engage with them by commenting on their posts and sharing their content.
        
        Focus on the Candidate Experience: The hiring process is a two-way street. Candidates are also evaluating your company as a potential employer. Focus on creating a positive candidate experience by communicating effectively and treating candidates with respect.
        
        Encourage Employee Referrals: Employee referral programs are a great way to build personal relationships with potential hires. Encourage your employees to refer candidates who they know personally and can vouch for.
        
        Use Video Interviews: Video interviews are a great way to build personal relationships with candidates who are located in different regions. Video interviews allow you to see the candidate's face and get a sense of their personality, which can help build a personal connection.
        
        The Bottom Line
        
        In conclusion, the hiring process is not just about finding the most qualified candidate, but also finding the best cultural fit. Building personal relationships with potential hires can lead to better hiring outcomes, including higher quality hires, lower turnover rates, and lower recruiting costs. By attending networking events, engaging on social media, focusing on the candidate experience, encouraging employee referrals, and using video interviews, companies can build personal relationships with potential hires and attract top talent.`,
        date: 'January 1, 2023',
        image: 'https://images.unsplash.com/photo-1557425529-b1ae9c141e7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
        title: 'The Impact of Technology on the Staffing Industry: Exploring the Pros and Cons',
        author: 'Jane Smith',
        content: `Introduction

        The staffing industry has undergone significant changes in recent years due to advancements in technology. With the use of artificial intelligence, automation, and other technological innovations, the industry has been transformed in ways that were previously unimaginable. While these technological advancements have brought about many benefits, they have also introduced several challenges that must be addressed. This article will explore the pros and cons of technology in the staffing industry and its impact on the industry as a whole.
        
        Pros of Technology in the Staffing Industry
        
        Increased Efficiency
        Technology has significantly improved the efficiency of the staffing industry. Automated systems can now perform tasks such as resume screening and candidate matching, which previously had to be done manually. This has allowed staffing agencies to handle more job orders and reduce the time it takes to fill a position. Additionally, automated systems can now handle administrative tasks such as scheduling interviews, sending reminders, and providing feedback, freeing up recruiters to focus on other tasks.
        
        Improved Candidate Experience
        Technology has also improved the candidate experience by making the application and interview process more streamlined and efficient. Candidates can now apply for jobs through online portals and receive automated updates on the status of their application. Video interviews have also become more prevalent, allowing candidates to interview remotely and at their convenience. This has made the application and interview process less stressful for candidates, resulting in a more positive experience overall.
        
        Increased Access to Candidates
        The use of technology has also expanded the pool of candidates available to staffing agencies. Social media and online job boards have made it easier for recruiters to find and connect with candidates who may not have otherwise been discovered. Additionally, technology has made it possible to source candidates globally, allowing staffing agencies to find talent that may not be available locally.
        
        Improved Quality of Hires
        Technology has made it easier to identify candidates who are the best fit for a job. Automated systems can now analyze resumes and job applications to identify the most qualified candidates. Additionally, machine learning algorithms can be used to predict which candidates are most likely to succeed in a particular role based on their skills and experience. This has led to better matches between candidates and job openings, resulting in higher quality hires.
        
        Cons of Technology in the Staffing Industry
        
        Dependence on Technology
        While technology has improved efficiency, it has also made staffing agencies more dependent on technology. This dependence can create challenges when systems fail or when there are technical issues. Additionally, some recruiters may become too reliant on automated systems and fail to exercise their own judgment when making hiring decisions.
        
        Reduced Personal Interaction
        The increased use of technology has reduced personal interaction between recruiters and candidates. While this has improved efficiency, it has also led to a more impersonal hiring process. Candidates may feel like they are just a number in a database, rather than a person with unique skills and experiences. This can result in a negative candidate experience and may make it more difficult to attract top talent in the future.
        
        Bias in Automated Systems
        Automated systems can also introduce bias into the hiring process. These systems are only as unbiased as the data they are trained on, and if the data is biased, the results will be as well. For example, if a system is trained on resumes from predominantly male applicants, it may be less likely to identify qualified female candidates. This can result in a less diverse pool of candidates and can perpetuate existing biases.
        
        Job Losses
        The use of technology has also led to job losses in the staffing industry. Automated systems can now perform tasks that were previously done by human recruiters, leading to a reduction in the number of recruiters needed. While this may improve efficiency and reduce costs for staffing agencies, it can also result in job losses for recruiters who may not have the skills needed to work with these new technologies.`,
        date: 'February 1, 2023',
        image: 'https://images.unsplash.com/photo-1573496130407-57329f01f769?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80'
    },
    {
        title: 'Diversity and Inclusion in the Staffing Industry: Why it Matters and What Companies Can Do to Improve',
        author: 'Bob Johnson',
        content: `Introduction:
        Diversity and inclusion are essential aspects of any organization. In the staffing industry, the importance of diversity and inclusion cannot be overstated. This article will discuss why diversity and inclusion matter in the staffing industry and what companies can do to improve their practices.
        
        Why Diversity and Inclusion Matter in the Staffing Industry:
        
        Talent Acquisition: The staffing industry is all about acquiring talent for companies. Having a diverse talent pool ensures that companies have access to a wider range of skills and experiences. This, in turn, helps companies to innovate and stay ahead of the competition.
        
        Better Decision Making: Diverse teams make better decisions. When teams are made up of people from different backgrounds, they are more likely to consider a wider range of perspectives. This can lead to more thoughtful and creative solutions.
        
        Improved Company Culture: A company that values diversity and inclusion creates a more welcoming and supportive environment for its employees. This can improve morale and lead to higher employee retention rates.
        
        Increased Customer Satisfaction: The staffing industry is all about meeting the needs of clients. A diverse and inclusive team is better equipped to understand the needs of a diverse client base. This can lead to higher levels of customer satisfaction and loyalty.
        
        What Companies Can Do to Improve Diversity and Inclusion in the Staffing Industry:
        
        Create a Diversity and Inclusion Policy: A formal policy is a great way to communicate a company's commitment to diversity and inclusion. This policy should outline specific goals and initiatives.
        
        Diversify Recruitment: Companies should actively seek out candidates from diverse backgrounds. This can be done by posting job openings in a variety of locations and using job boards that cater to specific demographics.
        
        Offer Diversity Training: Training programs can help to raise awareness of diversity and inclusion issues and provide employees with the tools they need to work effectively with people from diverse backgrounds.
        
        Create a Safe Environment for Feedback: Employees should feel comfortable providing feedback on diversity and inclusion issues. Companies should create a safe environment where employees can share their experiences and concerns without fear of retaliation.
        
        Provide Opportunities for Career Development: Companies should provide opportunities for employees from diverse backgrounds to develop their skills and advance in their careers. This can include mentoring programs, leadership development programs, and targeted training programs.
        
        Conclusion:
        
        Diversity and inclusion are crucial aspects of the staffing industry. A diverse and inclusive workforce can improve talent acquisition, decision making, company culture, and customer satisfaction. Companies can improve their diversity and inclusion practices by creating a formal policy, diversifying recruitment, offering diversity training, creating a safe environment for feedback, and providing opportunities for career development. By taking these steps, companies can create a more inclusive and welcoming workplace and stay ahead of the competition.`,
        date: 'March 1, 2023',
        // image: 'https://picsum.photos/300/200?random=3'
        image: 'https://images.unsplash.com/photo-1556484687-30636164638b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'
    }
];

const Blog = ({ data }) => {
    // Create a new state variable to hold the selected item value
    const [selectedItem, setSelectedItem] = useState({ title: null, author: null, content: null, date: null, image: null });

    // create a state variable for each card
    const [showFullContent0, setShowFullContent0] = useState(false);
    const [showFullContent1, setShowFullContent1] = useState(false);
    const [showFullContent2, setShowFullContent2] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const toggleShowFullContent0 = () => {
        setShowFullContent0(!showFullContent0);
    };

    const toggleShowFullContent1 = () => {
        setShowFullContent1(!showFullContent1);
    };

    const toggleShowFullContent2 = () => {
        setShowFullContent2(!showFullContent2);
    };

    // Function to handle "Learn More" button click and set the selected item value
    const handleShowMore = (item) => {
        setSelectedItem(item);
        setShow(true);
    };

    return (
        <>
            <div className='blog'>
                <h1>Blogs</h1 >
            </div>

            <div >
                <Carousel
                    // style={{ maxHeight: '50vh', maxWidth: '50vh', margin: ' 0 auto' }}
                    indicators={false}
                className={styles.carouselstyle}
                >
                    {blogPosts.map((item, index) => {
                        return (
                            <Carousel.Item key={index}
                                // style={{ maxHeight: '720px', maxWidth: '720px'}}
                                //             display: flex;
                                //   justify-content: center;
                                //   align-items: center;
                                // style={{

                                    // height: '720px', width: '720px',
                                    // display: 'flex', justifyContent: 'center', alignItems: 'center',
                                // }}
                            >
                                <Card key={index} className={"p-3 mx-auto $styles.cards"}>
                                    <Card.Img variant="top" src={item.image} className={styles.cardimage} />
                                    <Card.Body>
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Text className={styles.cartext}>
                                            - {item.author}
                                            {/* {showFullContent0 && index === 0
                                                ? item.content
                                                : `${item.content.substring(0, 10)}...`}
                                            {showFullContent1 && index === 1
                                                ? item.content
                                                : `${item.content.substring(0, 10)}...`}
                                            {showFullContent2 && index === 2
                                                ? item.content
                                                : `${item.content.substring(0, 10)}...`} */}
                                        </Card.Text>
                                        <span className="d-flex justify-content-center">                                            
                                            {/* // Render the "Learn More" button with the handleShowMore function */}
                                            <Button variant="primary" onClick={() => handleShowMore(item)}>Learn More</Button>

                                            {/* // Pass the selectedItem value to the Modal component using the item prop */}
                                            <Modal size="fullscreen" show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        {selectedItem.title}
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div dangerouslySetInnerHTML={{ __html: selectedItem.content }} />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </span>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        </>
    );
};

export default Blog;
