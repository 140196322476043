import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center mt-5">
          <h1>404 Not Found</h1>
          <p>We couldn't find what you were looking for.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
