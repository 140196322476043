function Career() {
    return (
        <>
            {/* <div>
                <h1>Unlock Your Real Potential with Abhi IT Solutions</h1>
                <p>Did you know that more than half of the US workforce is unhappy with their jobs? One of the main reasons for this is the lack of career guidance and self-awareness. At Abhi IT Solutions, we believe that self-discovery is crucial to unlocking your full potential. Our personalized approach helps you identify your strengths, interests, and skillsets to guide you towards better opportunities and your dream job.</p>
                <h2>Why Choose Abhi IT Solutions?</h2>
                <ul>
                    <li>We are the best in class for global clients in the IT industry.</li>
                    <li>Our personalized approach helps bring out your true potential and includes training programs.</li>
                    <li>We offer a comfortable and hassle-free working atmosphere.</li>
                    <li>We provide training for any technology you're interested in.</li>
                    <li>We uncover the best opportunities for you.</li>
                    <li>Our customized strategies provide immense benefits.</li>
                    <li>We believe in engaging with our consultants through various activities, team events, gigs, and awards to make you feel valued and special.</li>
                    <li>We appreciate your referrals and offer perks for every successful referral.</li>
                </ul>
                <p>Join Abhi IT Solutions and unlock your true potential today!</p>
            </div> */}
            <div class="header">
                <h1 class="text-center">Unlock Your Real Potential with Abhi IT Solutions</h1>
            </div>
            <div class="container">
                {/* <h2 class="text-center mb-4">Why Choose Abhi IT Solutions?</h2> */}
                <ul class="points mt-5">
                    <li>We are the best in a class of global clients in the IT industry.</li>
                    <li>Our personalized approach brings out your real talent and we tune with training programs.</li>
                    <li>We make a comfortable and hassle-free working atmosphere.</li>
                    <li>You name any technology, we are here to train you.</li>
                    <li>We untap the best opportunities for you.</li>
                    <li>Our customized strategies get you an immense benefit.</li>
                    <li>We are keen to engage with you in various activities, team events, gigs, and awards to make you realize how special you are to us.</li>
                    <li>Your reference is appreciable and we offer perks for every referral.</li>
                </ul>
                <div class="text-center">
                    <a href="#" class="btn btn-primary">Join Us Now</a>
                </div>
            </div>
        </>
    )
}

export default Career;