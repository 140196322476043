import { Container, Row, Col, Button, Image, Modal } from 'react-bootstrap';
import { useState } from 'react';
import './Services.css'

function Services() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return (
        <div style={{ marginTop: '50px' }}>
            <Container className='services'>
                <div className="welcome-section text-center py-5">
                    {/* <p className="mb-0">Welcome to ABITSOLUTIONS</p> */}
                    <h4>Welcome to ABHI IT SOLUTIONS</h4>
                </div>

                <Row className="my-5">
                    <Col xs={12} md={4} className="text-center mt-3 mb-3">
                        <Image src="hiring.png" alt="Reusable Elements Logo" width={100} className="mr-3" />
                        <p className="my-3">"We provide superior hiring solutions with our rigorous and effective process, ensuring only the most talented individuals join your team."</p>
                        <Button variant="primary" onClick={handleShow}>Learn More</Button>
                        <Modal size="lg" show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Image src="hiring.png" alt="Reusable Elements Logo" width={70} className="mr-3" /> Strong Hiring Standards
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Our hiring company boasts a powerful and rigorous hiring procedure, backed by industry-leading standards. We leave no stone unturned in our quest for the best talent, ensuring that we deliver top-notch candidates to our clients. Our team of seasoned recruiters works tirelessly to identify and attract the best talent in the market, and our rigorous selection process ensures that only the most qualified and capable candidates make the cut. With our unwavering commitment to excellence and a proven track record of success, we are the go-to choice for businesses looking to hire the best of the best.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>

                    <Col xs={12} md={4} className="text-center mt-3 mb-3">
                        <Image src="light-bulb.png" alt="Design Innovation Logo" width={100} className="mr-3" />
                        <p className="my-3">"Tailored staffing solutions that fit your unique needs and drive success. Our customized approach ensures the right talent is matched with the right opportunity."</p>
                        <Button variant="primary" onClick={handleShow1} >Learn More</Button>
                        <Modal size="lg" show={show1} onHide={handleClose1}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Image src="light-bulb.png" alt="Design Innovation Logo" width={70} className="mr-3" /> Customized Staffing Solutions
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Our customized staffing solutions help clients find the right talent for their organization while saving time and money. We use our industry expertise and extensive network to identify and recruit top talent that matches our clients' unique requirements. This not only ensures that our clients get the right people for the job but also helps them build a high-performing team that drives business growth.

                                With our customized staffing solutions, clients can rest assured that they will receive personalized attention and support throughout the hiring process. We are committed to delivering high-quality staffing solutions that exceed our clients' expectations and help them achieve their business goals.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose1}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>

                    <Col xs={12} md={4} className="text-center mt-3 mb-3">
                        <Image src="technology.png" alt="Best Selling Logo" width={100} className="mr-3" />
                        <p className="my-3">"Efficiently scale and adapt to meet your evolving staffing needs with our flexible solutions."</p>
                        <Button variant="primary" onClick={handleShow2} >Learn More</Button>
                        <Modal size="lg" show={show2} onHide={handleClose2}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Image src="technology.png" alt="Best Selling Logo" width={70} className="mr-3" /> Scalability and flexibility
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Our IT staffing solutions are designed to provide scalable and flexible staffing services that meet the needs of businesses of all sizes. We can easily ramp up or down the number of resources provided depending on the changing requirements of your project. Our staffing solutions are designed to be agile and adaptable to ensure that our clients can achieve their goals without any disruptions or delays. Our team of experts is dedicated to providing the best possible staffing solutions that help our clients to stay ahead of the competition.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose2}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Services;